
import { Link, navigate, graphql } from "gatsby";
import React from 'react';
import styles from "../../../components/innerPage.module.css"
import Footer from '../../../components/residentsFooter';
import Header from '../../../components/UkResidents/HCP/header';
import ScrollAnimation from 'react-animate-on-scroll';
import SEO from "../../../components/seo" 
import {capitalizeFirstLetter, isNullUndefined} from "../../../util"
import { ValidateRoute } from '../../../util/route-helper';
import TemplateWrapper from "../../../components/TemplateWrapper"

export const pageQueryCurrentVacanciesTemplateUkHcp = graphql`
    query ($slug: String!, $spaceId: String!) {
        contentfulCurrentVacanciesTemplate(slug: { eq: $slug }, spaceId: {eq: $spaceId}) {
          spaceId
          slug
          title
        }
        allContentfulCareersItemTemplate {
            edges {
              node {
                country
                jobTitle
                jobType
                location
                slug
                spaceId
                isPlaceholder
              }
            }
        }
    }
`

const CurrentVacancies = (props) => {

  let {title, slug, spaceId} = props.data.contentfulCurrentVacanciesTemplate;
  React.useEffect(() => {
    
  }, []);
  const edges = props?.data?.allContentfulCareersItemTemplate?.edges;

  let ukHcpEdges = edges?.filter(edge => edge?.node?.spaceId === process.env.GATSBY_UK_RESIDENTS_HCP_SPACE_ID && !edge?.node?.isPlaceholder)

  return ( 
   <TemplateWrapper>
      <Header/>
         <SEO title={`${capitalizeFirstLetter(title)} | UK Residents | HCP`} />
          
                <div className={styles.banner} style={{background: 'url(https://cdn.pixabay.com/photo/2016/09/23/20/54/doors-1690423_960_720.jpg)'}}>
                <div className={styles.overlay}></div>
                            <div className="container">
                               <div className="row">
                                   <div className="col-12 col-sm-12 col-md-12">
                                    
                                   <ScrollAnimation animateIn="fadeIn"><h1 className="">{title}</h1></ScrollAnimation>
                                   <ol className="custom_breadcrumb">
                                   <li className="breadcrumb-item"><Link to="/UkResidents/HCP/home">Home</Link></li>
                                        <li className="breadcrumb-item"><a href="#">Careers</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">{title}</li>
                                    </ol>
                                   </div>
                                   </div>
                            </div>
                </div>
                <ScrollAnimation animateIn="fadeIn">
                <div className={styles.content}>
                        <div className="container pt-5 pb-5">
                        <div className="row">
                        <div className="col-12 col-sm-12 col-md-12"  >
                     
                    {(!isNullUndefined(ukHcpEdges) && ukHcpEdges.length > 0 ) ? (
                      <table className="table careeers">
                      <thead className="thead-light">
                          <tr>
                          <th scope="col">Job Title</th>
                          <th scope="col">Job Type</th>
                          <th scope="col">Country</th>
                          <th scope="col">Location</th>
                          <th></th>
                          </tr>
                      </thead>
                      <tbody>
                      {ukHcpEdges.map((item) => {
                          return (
                              <tr key={item.node.slug}>
                                  <td scope="row">{item.node.jobTitle}</td>
                                  <td>{item.node.jobType}</td>
                                  <td>{item.node.country}</td>
                                  <td>{item.node.location}</td>
                                  <td><span style={{whiteSpace:'nowrap'}} onClick={() => navigate(`/UkResidents/HCP/Careers/${slug}/${item.node.slug}`)}>Apply now</span></td>
                              </tr>
                          )
                      })}
                      </tbody>
                      </table>
                    ) : (
                      <table className="table careeers">
                <thead className="thead-light">
                    <tr>
                    <th scope="col">Job Title</th>
                    <th scope="col">Job Type</th>
                    <th scope="col">Country</th>
                    <th scope="col">Location</th>
                    <th ></th>
                    </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{border: "none"}}>
                      Nothing to show at the moment, please try again later.
                    </td>
                  </tr>
                  </tbody>
                 </table>
                    )}


                      </div>
                     

                    </div>
                  

                        </div>
                     
               </div>
             </ScrollAnimation>
                <Footer/>
   </TemplateWrapper>
 
    );
}
 
export default CurrentVacancies;